import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { filter, firstValueFrom, Observable } from 'rxjs';
import { UserService } from 'src/app/services/session.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TagsService } from 'src/app/services/tags.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-books',
    templateUrl: './books.component.html',
    styleUrls: [
        './books.component.scss'
    ]
})
export class BooksComponent implements OnInit {
    public isDesktop: boolean = false;
    public showAddBooks: boolean = false;

    public filter: any = {};
    public tagFilters: string = '';
    
    public loading$: Observable<boolean> = this.searchService.loading$;
    public bookCount$: Observable<number> = this.searchService.bookCount$;
    
    public searchValue!: string | undefined;
    public theme$ = this.themeService.theme$;

    constructor(
        private searchService: SearchService,
        private sessionService: UserService,
        private tagsService: TagsService,
        private breakpointObserver: BreakpointObserver,
        private themeService: ThemeService
    ) { }

    public async ngOnInit(): Promise<void> {
        // TODO check if this is needed
        // document.body.classList.add('bg-dark');

        this.searchService.activeFilters$.subscribe(async (filter) => {
            this.filter = filter;
            this.searchValue = filter.searchValue;

            await this.tagsService.getAllTags()
                .then(tags => {
                    this.tagFilters = tags
                        .filter(tag => this.filter.tagIds?.includes(tag.id))
                        .map(tag => tag.displayName)
                        .join(', ');
                });
        });

        // Checks if we need to show the filters as fixed sidebar (if there's room) or collapsible sidebar
        this.breakpointObserver.observe(['(min-width: 1200px)']).subscribe((result) => {
            this.isDesktop = result.matches;
        });

        const user = await firstValueFrom(this.sessionService.user$.pipe(filter(user => !!user)));
        this.showAddBooks = user?.isAdmin ?? false;
    }
}
