<div *ngIf="(loading$ | async)" class="loading-section">
    <div class="d-flex w-100 justify-content-center" >
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</div>
<hr *ngIf="!(loading$ | async)" class="d-none d-lg-block my-4">

<div class="theme-{{ theme$|async }}" *ngFor="let book of books">
    <div class="book-row dark" (click)="isDesktop ? {} : goToBook(book.id)">
        <div class="image-box cursor-pointer" (click)="!isDesktop ? {} : goToBook(book.id)">
            <a class="">
                <img *ngIf="book.uuid" class="book-cover" [src]="getImageUrl(book.uuid)">
                <img *ngIf="!book.uuid" class="book-cover" src="/assets/book.png">
            </a>
        </div>

        <div class="book-description">
            <div class="d-flex flex-column">
                <h2 class="book-title text-truncate-2 cursor-pointer" (click)="!isDesktop ? {} : goToBook(book.id)">{{ book.title }}</h2>
                <span *ngIf="book.subtitle" class="book-subtitle">{{ book.subtitle }}</span>
                <p>
                    <span class="">
                        {{ book.authors }}
                    </span>
                    <span class="" *ngIf="book.authors.length && book.publishers.length"> | </span>
                    <span class="">{{ book.publishers }}</span>
                </p>
                <p class="small  text-truncate-2">{{ book.description }}</p>
            </div>

            <div class="w-100">
                <div class="d-flex flex-row flex-wrap">
                    <ui-tag *ngFor="let tag of book.categories; let i = index" color="primary"
                        class="p-1">
                        {{ tag }}
                    </ui-tag>
                    <ui-tag *ngFor="let tag of book.themes; let i = index" color="secondary"
                        class="p-1">
                        {{ tag }}
                    </ui-tag>
                </div>
            </div>
        </div>

        <div class="book-actions">
            <button class="btn btn-primary w-100" (click)="goToBook(book.id)">
                <span>Details</span>
            </button>
            <button class="btn btn-secondary w-100" (click)="addBookToReservation(book)">
                <span *ngIf="book.available">Reserveer</span>
                <span *ngIf="userReservedBook(book)">✓ Gereserveerd</span>
                <span *ngIf="!userReservedBook(book) && !book.available">Wachtlijst</span>
            </button>
        </div>
    </div>
    <hr class="my-4">
</div>

<ng-container *ngIf="(currentPage$ | async) as currentPage">
    <div *ngIf="pagesToDisplay" class=" w-100 w-md-50 mx-auto mt-5 text-center fs-6 text-purple-color">
        <span *ngFor="let page of pagesToDisplay" class="me-4">
            <span *ngIf="page === '...'">...</span>
            <span class="cursor-pointer link" *ngIf="page !== '...'"
                [class.currentPage]="isCurrentPage(currentPage, page)" (click)="setPage(page)">{{ page }}</span>
        </span>
    </div>
</ng-container>