import { BehaviorSubject } from 'rxjs';
import { SupabaseService } from './supabase.service';
import { UserInfo } from '../types/user/session-info.type';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public user$: BehaviorSubject<UserInfo|null|undefined> = new BehaviorSubject<UserInfo|null|undefined>(undefined);

    constructor(
        private supabaseService: SupabaseService
    ) {}

    public async getUser(): Promise<void> {
        const user = await this.supabaseService.getSession();
        this.user$.next(user ?? undefined);
    }

    public async login(loginData: any): Promise<void> {
        const user = await this.supabaseService.signInWithPassword(loginData.email, loginData.password);
        this.user$.next(user);
    }

    public async logout(): Promise<void> {
        await this.supabaseService.client.auth.signOut();
        this.user$.next(undefined);
        localStorage.clear();
    }

    // TODO type
    public async getBasket(): Promise<any> {
        let { data: rawBasket, error: basketError } = await this.supabaseService.client
            .from('baskets')
            .select('*')
            .limit(1);

        if (!rawBasket || rawBasket.length === 0) {
            return {};
        }

        const basket = rawBasket[0];

        let { data: copies, error: copiesError } = await this.supabaseService.client
            .from('copies')
            .select('id, bookId')
            .eq('basket_id', basket.id);
        
        basket.copies = copies;
        const bookIds = copies?.map(copy => copy.bookId) ?? [];

        let { data: books, error: bookError } = await this.supabaseService.client
            .from('books_public_view')
            .select('id, title, authors, uuid')
            .in('id', bookIds);

        basket.books = books;
        return basket;
    }
}