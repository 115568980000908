import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, Subject, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { LibraryService } from 'src/app/services/library.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/session.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Book, Copy, Publisher, Tag } from 'src/app/types/supabase/supabase.models';

@Component({
    selector: 'app-books-list',
    templateUrl: './books-list.component.html',
    styleUrls: [
        './books-list.component.scss',
        './loading-spinner.scss'
    ]
})
export class BooksListComponent implements OnInit, OnDestroy {
    public books: any[] = [];
    public pagesToDisplay?: string[];

    public loading$: Observable<boolean> = this.searchService.loading$;
    public currentPage$: Observable<any> = this.searchService.activeFilters$.pipe(map(f => f.page));

    public isDesktop: boolean = false;
    private destroyed$: Subject<void> = new Subject();
    public theme$ = this.themeService.theme$;
    public basket: any|null;

    @Output() public reloadBooks: EventEmitter<any> = new EventEmitter();

    constructor(
        private libraryService: LibraryService,
        private searchService: SearchService,
        private route: ActivatedRoute,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private themeService: ThemeService,
        private userService: UserService
    ) { }

    public async ngOnInit(): Promise<void> {
        const page = this.getPageQueryParam();
        const searchValue = this.getSearchValueQueryParam();
        const tagIds = this.getTagsQueryParam();
        await this.searchService.filter({ page, searchValue, tagIds });

        this.searchService.activeFilters$
            .pipe(
                withLatestFrom(this.searchService.books$),
                takeUntil(this.destroyed$)
            )
            .subscribe(([filters, response]) => {
                if (response.books) {
                    this.books = response.books;

                    const pageCount = Math.ceil(response.totalCount / 15);
                    this.pagesToDisplay = this.getPagesToDisplay(pageCount, filters.page);
                }
            });

        this.searchService.loading$
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe(loading => {
                if (loading) {
                    this.books = [];
                }
            })

        this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((result) => {
            this.isDesktop = result.matches;
        });

        this.basket = await this.userService.getBasket();
    }

    private getPageQueryParam(): number | null {
        const page = this.route.snapshot.queryParamMap.get('page');
        if (page) {
            return parseInt(page);
        }
        return 1;
    }

    private getTagsQueryParam(): number[] | null {
        const tags = this.route.snapshot.queryParamMap.get('tags');
        if (tags) {
            return tags.split(',').map(tag => parseInt(tag));
        }
        return null;
    }

    private getSearchValueQueryParam(): string | null {
        return this.route.snapshot.queryParamMap.get('searchValue');
    }

    public getPagesToDisplay(pageCount: number, currentPage: number): string[] {
        const pages: string[] = [];
        for (let i = 1; i <= pageCount; i++) {
            const difference = Math.abs(i - (currentPage ?? 1));
            const lastOrSecondLastDifference = Math.abs(i - (pageCount ?? 0));
            if (difference < 2 || i <= 2 || lastOrSecondLastDifference < 2) {
                pages.push(i.toString());
            }
            if (difference === 2) {
                pages.push('...');
            }
        }
        return pages;
    }


    public goToBook(id: number): void {
        this.router.navigate(['boeken', 'id', id]);
    }

    public getImageUrl(name?: string): string {
        return this.libraryService.getBookCoverUrl(name);
    }

    public setPage(pageNumber: string): void {
        const page = parseInt(pageNumber);
        this.searchService.filter({ page });
    }

    public getTags(book: Book): Tag[] {
        return book.tags
            .sort((a, b) => {
                if (a.isCategory !== b.isCategory) {
                    return a.isCategory ? -1 : 1;
                }
                return a.displayName.localeCompare(b.displayName);
            });
    }

    public async addBookToReservation(book: any): Promise<void> {
        await this.libraryService.addBookToReservation(book.id);
        this.basket = await this.userService.getBasket();
        book.available = false;
    }

    public getPublishers(publishers: Publisher[]): string[] {
        return publishers.map(p => p.name ?? '').filter(p => p != '') ?? [];
    }

    public ngOnDestroy(): void {
        this.destroyed$.complete();
    }

    public isAvailable(book: Book): boolean {
        return book.copies
            .filter((copy: Copy) => copy.state === 1)
            .length > 0
    }

    public userReservedBook(listBook: any): boolean {
        const matches = this.basket?.books?.filter((book: any) => book.id === listBook.id);
        return matches?.length > 0;
    }

    public isCurrentPage(currentPage: number, pageInPagination: string): boolean {
        return parseInt(pageInPagination) === currentPage;
    }
}
