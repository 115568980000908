<div class="container d-flex flex-column">
    <app-breadcrumbs *ngIf="breadcrumbs && breadcrumbs.length" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="container d-flex flex-column align-items-center justify-content-center my-3">
        <form *ngIf="bookForm" class="w-100 w-md-75 w-xl-50" [formGroup]="bookForm" (ngSubmit)="submitBook()">
            <div class="form-group py-3">
              <label for="bookTitle">Titel:</label>
              <input class="form-control" type="text" id="bookTitle" formControlName="bookTitle">
            </div>
          
            <div class="form-group py-3">
              <label for="authors">Auteurs:</label>
              <ng-select class="mt-1 mb-2" name="authors" [items]="authorOptions" formControlName="authors" placeholder="Auteurs" bindLabel="name" [multiple]="true" [addTag]="true"></ng-select>
            </div>
          
            <div class="form-group py-3">
              <label for="publisher">Uitgever:</label>
              <ng-select class="mt-1 mb-2" name="publishers" [items]="publisherOptions" formControlName="publisher" placeholder="Uitgeverij" bindLabel="name" [addTag]="true">
            </ng-select>
            </div>
          
            <div class="form-group py-3">
              <label for="description">Beschrijving:</label>
              <textarea class="form-control" id="description" formControlName="description" rows="15"></textarea>
            </div>
          
            <div class="form-group py-3">
              <label for="isbn">ISBN10:</label>
              <input class="form-control" type="text" id="isbn10" formControlName="isbn10">
            </div>
          
            <div class="form-group py-3">
              <label for="isbn">ISBN13:</label>
              <input class="form-control" type="text" id="isbn13" formControlName="isbn13">
            </div>
          
            <div class="form-group py-3">
              <label for="tags">Categorieën:</label>
              <ng-select class="mt-1 mb-2" name="tags" [items]="tagOptions" formControlName="tags" placeholder="Categorieën" bindLabel="displayName" [multiple]="true"></ng-select>
            </div>
          
            <div class="form-group py-3">
              <label for="publishedYear">Gepubliceerd op (jaar):</label>
              <input type="number" class="form-control" id="publishedYear" formControlName="publishedYear">
            </div>
          
            <div class="form-group py-3">
              <label for="pageCount">Aantal blz:</label>
              <input type="number" class="form-control" id="pageCount" formControlName="pageCount">
            </div>
          
            <div class="form-group py-3">
              <label for="bookCover">Boekomslag:</label>
              <form>
                  <label for="fileupload" class="rounded p-2 bg-yellow-color brand-font text-dark cursor-pointer">
                      <input type="file" id="fileupload" accept="image/*" (change)="uploadImage($event)">
                      Upload afbeelding
                  </label>
              </form>
              <div *ngIf="image" class="mt-3 container d-flex flex-row justify-content-center">
                  <img class="mx-3 img-lg" [src]="image">
              </div>
      
          </div>
          
            <button class="btn btn-primary" type="submit">Submit</button>

            <div class="pt-5">
              <p>
                <small *ngIf="book.copies.length === 0 || book.copies.length > 1">Dit boek heeft momenteel {{book.copies.length}} exemplaren.<br></small>
                <small *ngIf="book.copies.length === 1">Dit boek heeft momenteel 1 exemplaar.<br></small>
                <small>Klik hieronder om een nieuw exemplaar toe te voegen, bv omdat er maar 1 kopie in de database steekt, maar er in de realiteit
                  meerdere boeken zijn in de bibliotheek. Dit zorgt ervoor dat hetzelfde boek meerdere keren
                  kan worden uitgeleend.
                </small>
              </p>
              <button class="btn btn-primary btn-sm" type="button" (click)="addCopy()">Nieuw exemplaar</button>
            </div>
          </form>
    </div>
</div>