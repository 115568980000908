<section *ngIf="basket && basket.books" class="reservations theme-{{theme$|async}}">
    <h1 class="d-none d-md-block">Mijn reservatie</h1>
    <div class="container-wide reservation-section">
        <span *ngIf="basket.books.length === 0">Je hebt nog geen boeken gereserveerd.</span>
        <div class="books">
            <h2>Jouw selectie</h2>
            <div class="book-row" *ngFor="let book of basket.books">
                <div class="image-box">
                    <img class="book-cover" src="https://uuxamqpddbrazazfzdqa.supabase.co/storage/v1/object/public/covers/{{book.uuid}}.jpg">
                </div>
                <div class="book-description">
                    <span class="text-truncate-2 title fw-bold">{{ book.title }}</span>
                    <span class="text-truncate-2 authors">{{ book.authors }}</span>
                    <div class="pt-3 d-flex flex-row align-items-center cursor-pointer" (click)="removeFromBasket(book)">
                        <img *ngIf="(theme$|async)==='light'" src="assets/trash-dark.svg">
                        <img *ngIf="(theme$|async)==='dark'" src="assets/trash-light.svg">
                        <span>Verwijder</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <h3 class="">Overzicht</h3>
            <div class="content">
                <span>Aantal boeken: <span class="fw-bold">{{ basket.books.length }}</span></span>
                <ng-container *ngIf="basket.books.length <= 2">
                    <div class="price">
                        <span>Te betalen borg: <span class="fw-bold">{{ basket.books.length * 5 }} EUR</span></span>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="isGift" id="is-gift">
                        <label class="form-check-label cursor-pointer" for="is-gift">
                            Borg doneren als gift
                        </label>
                    </div>
                    <span class="small">
                        <span *ngIf="isGift">
                            Bedankt voor je donatie!<br>4
                        </span>
                        <span *ngIf="!isGift">
                            Je krijgt het bedrag terug wanneer je het boek terugbrengt.<br>
                        </span>
                        Breng zeker gepast cash geld mee.
                    </span>
                    <button class="btn btn-primary" (click)="goToNextStep()">Reserveer</button>
                </ng-container>

                <ng-container *ngIf="basket.books.length > 2">
                    <span>
                        Je mag per keer maximaal <span class="fw-bold">2 boeken</span> lenen.<br>
                        Verwijder boeken uit je mandje om door te gaan met de reservatie.
                    </span>
                    <button class="btn btn-primary disabled" (click)="goToNextStep()">Reserveer</button>
                </ng-container>
            </div>
        </div>
    </div>
</section>