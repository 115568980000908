import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/services/library.service';
import { SearchService } from 'src/app/services/search.service';
import { TagsService } from 'src/app/services/tags.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Publisher, Tag } from 'src/app/types/supabase/supabase.models';

@Component({
    selector: 'app-books-desktop-filter',
    templateUrl: './books-desktop-filter.component.html',
    styleUrls: ['./books-desktop-filter.component.scss']
})
export class BooksDesktopFilterComponent implements OnInit, OnDestroy {

    public allTags!: Tag[];
    public allCategories!: Tag[];
    public allThemes!: Tag[];

    public allPublishers!: Publisher[];

    public filter: any = {};
    public filterForm!: FormGroup;
    
    public filterCollapsed: boolean = true;
    public theme$ = this.themeService.theme$;

    get categoriesFormArray(): FormArray<FormControl<boolean>> {
        return this.filterForm.get('categories') as FormArray<FormControl<boolean>>;
    }
    get themesFormArray(): FormArray<FormControl<boolean>> {
        return this.filterForm.get('themes') as FormArray<FormControl<boolean>>;
    }

    constructor(
        private tagsService: TagsService,
        private libraryService: LibraryService,
        private searchService: SearchService,
        private themeService: ThemeService,
        private fb: FormBuilder
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.allTags = await this.tagsService.getAllTags();
        this.allCategories = this.allTags.filter(tag => tag.isCategory);
        this.allThemes = this.allTags.filter(tag => !tag.isCategory);

        this.allPublishers = await this.libraryService.getPublishers();
        this.searchService.activeFilters$.subscribe(filter => {
            this.filter = filter;

            this.filterForm = this.fb.group({
                categories: this.fb.array(
                  this.allCategories.map((tag) => new FormControl(filter.tagIds?.includes(tag.id))) 
                ),
                themes: this.fb.array(
                    this.allThemes.map((tag) => new FormControl(filter.tagIds?.includes(tag.id))) 
                ),
            });
        });

    }

    public triggerSearch(): void {
        this.searchService.filter({
            tagIds: this.getCheckedTags(),
            // publisherIds: this.selectedPublishers?.map(publisher => publisher.id) ?? null,
            page: 1
        });
    }

    public getTagNamesById(ids: number[]): string {
        return this.allTags?.filter(tag => ids.includes(tag.id)).map(tag => tag.displayName).join(', ');
    }

    private getCheckedTags(): any[] {
        const checkedTags: any[] = [];
        this.categoriesFormArray.value.forEach((checked, index) => {
            if (checked) {
                checkedTags.push(this.allCategories[index].id);
            }
        });
        this.themesFormArray.value.forEach((checked, index) => {
            if (checked) {
                checkedTags.push(this.allThemes[index].id);
            }
        });
        return checkedTags;
    }

    public ngOnDestroy(): void {
        this.searchService.reset();
    }
}
