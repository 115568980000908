<div class="container p-3 d-flex flex-column">
    <app-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1>Boek lenen: {{ book.title }}</h1>

    <div class="pt-4">
        <p class="w-100 w-md-75" [innerHTML]="bookRequestText"></p>
    </div>

    <p *ngFor="let alert of alerts" class=" w-100 w-md-75">
        <ngb-alert [type]="alert.type" (closed)="close(alert)">{{ alert.message }}</ngb-alert>
    </p>

    <form [formGroup]="form" *ngIf="showSubmit">
        <textarea formControlName="availability" class="form-control mt-2 w-100 w-md-75" placeholder="Vb. 'Elke maandagavond vanaf 17u.'" rows="2"></textarea>

        <p class="mt-3">
            Extra vragen / opmerkingen / ...
        </p>
        <textarea formControlName="comments" class="form-control my-4 w-100 w-md-75" placeholder="..." rows="4"></textarea>
    
        <ui-button color="primary" class="pt-4" (click)="requestBook()">Reserveer dit boek</ui-button>
    </form>
   
</div>