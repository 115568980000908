import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SupabaseService } from './supabase.service';
import { v4 as uuidv4 } from 'uuid';
import { Author, Book, InsertBook, Publisher, Request, Tag, UpdateBook } from '../types/supabase/supabase.models';
import { State } from '../types';
import { PostgrestSingleResponse } from '@supabase/supabase-js';

@Injectable({
    providedIn: 'root'
})
export class LibraryService {

    constructor(
        private supabaseService: SupabaseService
    ) {}

    public async getBook(id: number): Promise<any> {
        const select = `
            *,
            authors(*),
            tags(*),
            publishers(*),
            copies(*)
        `;
        const result = await this.supabaseService.client
            .from('books')
            .select(select)
            .eq('id', id)
            .maybeSingle<Book>();

        if (result.error || !result.data) {
            console.error(result.error);
            throw new Error('An error occured while fetching a book.');
        }
        const book = result.data;
        book.tags = book.tags.filter(tag => tag.showInFrontend);
        return book;
    }

    public async getBooks(filters: any): Promise<{ books: Book[], totalCount: number }> {
        const pageSize = 15;

        const queryParams = {
            'search_term': filters.searchValue?.length ? filters.searchValue : null,
            'author_ids': filters.authorIds?.length ? filters.authorIds : null,
            'tag_ids': filters.tagIds?.length ? filters.tagIds : null,
            'publisher_ids': filters.publisherIds?.length ? filters.publisherIds : null
        };
        const bookIdsResponse = await this.supabaseService.client.rpc('find_books_2', queryParams)
            .range((filters.page - 1) * pageSize, filters.page * pageSize - 1)
            .limit(pageSize);

        const resultCountResponse = await this.supabaseService.client.rpc('find_books_2_total_count', queryParams);

        const bookIds: number[] = bookIdsResponse.data.map((d: any) => d.id);
        const totalCount: number = resultCountResponse.data[0].id;

        // const select = `
        //     *,
        //     authors(*),
        //     tags(*),
        //     publishers(*),
        //     copies(*)
        // `;
        const { data, error } = await this.supabaseService.client
            .from('books_public_view')
            .select('*')
            .in('id', bookIds);

        if (error || !data) {
            console.error(error);
            throw new Error('An error occured while fetching a book.');
        }

        return { books: data, totalCount };
    }

    public async getLatestBook(): Promise<Book> {
        const result = await this.supabaseService.client
            .from('books')
            .select('*, authors(*)')
            .order('created', { ascending: false })
            .limit(1)
            .single();

        if (result.error) {
            console.error(result.error);
            throw new Error('An error occured while fetching a book.');
        }
        
        return result.data;
    }

    public async addImage(image: Blob, bookId?: number): Promise<string> {
        const uuid = uuidv4();
        const fileName = `${uuid}.jpg`;
        await this.supabaseService.client.storage.from('covers')
            .upload(fileName, image, { contentType: 'image/png' });

        if (bookId) {
            await this.supabaseService.client.from('books')
                .update({ image: uuid })
                .eq('id', bookId);
        }

        return uuid;
    }

    public async updateBook(book: UpdateBook, authors: Author[], tags: Tag[], publisher: Publisher): Promise<void> {
        book = { ...book, enableSync: false };
        await this.supabaseService.client.from('books')
            .update(book)
            .eq('id', book.id)

        await this.saveBookAuthors(book, authors);
        await this.saveBookTags(book, tags);
        await this.saveBookPublishers(book, publisher);
    }

    public async addBook(book: InsertBook, authors: Author[], tags: Tag[]): Promise<number | undefined> {
        book = { ...book, enableSync: false };
        const { data } = await this.supabaseService.client.from('books')
            .insert(book)
            .select('id')
            .single();

        if (data) {
            const addedBook = await this.getBook(data.id);

            await this.saveBookAuthors(addedBook, authors);
            await this.saveBookTags(addedBook, tags);
            await this.addCopy(addedBook);

            return data.id;
        }

        return undefined;
    }

    public async addCopy(book: Book): Promise<void> {
        await this.supabaseService.client.from('copies')
            .insert({
                bookId: book.id,
                state: 1
            })
            .select('id')
            .single();
    }

    public async saveBookAuthors(book: UpdateBook, authors: Author[]): Promise<void> {
        const currentAuthorBooks = await this.supabaseService.client.from('authors_books')
            .select('authorId')
            .eq('bookId', book.id);

        let addedAuthors = [];
        if (currentAuthorBooks.data?.length) {
            const currentAuthors = currentAuthorBooks.data.map(a => a.authorId);
            const { data, error } = await this.supabaseService.client.from('authors_books')
                .delete()
                .eq('bookId', book.id)
                .not('authorId', 'in', `(${authors.map(a => a.id).join()})`);

            addedAuthors = authors.filter(a => !currentAuthors.includes(a));
        } else {
            addedAuthors = authors;
        }

        for (let author of authors) {
            if (!author.id) {
                const { data } = await this.supabaseService.client.from('authors')
                    .insert(author)
                    .select('id')
                    .single();

                if (data) {
                    author.id = data.id;
                }
            }   
        }

        await this.supabaseService.client.from('authors_books')
            .upsert(addedAuthors.map(a => ({ bookId: book.id, authorId: a.id })))
    }

    public async saveBookPublishers(book: UpdateBook, publisher: Publisher): Promise<void> {
        const currentBooksPublishers = await this.supabaseService.client.from('books_publishers')
            .select('publisherId')
            .eq('bookId', book.id);

        if (currentBooksPublishers.data?.length) {
            const { data, error } = await this.supabaseService.client.from('books_publishers')
                .delete()
                .eq('bookId', book.id)
                .neq('publisherId', publisher.id);
        }

        if (publisher && !publisher.id) {
            const { data } = await this.supabaseService.client.from('publishers')
                .insert(publisher)
                .select('id')
                .single();

            if (data) {
                publisher.id = data.id;
            }
        }   

        if (publisher) {
            await this.supabaseService.client.from('books_publishers')
                .upsert({ bookId: book.id, publisherId: publisher.id })
        }
    }

    public async saveBookTags(book: UpdateBook, tags: Tag[]): Promise<void> {
        const currentBookTags = await this.supabaseService.client.from('books_tags')
            .select('tagId')
            .eq('bookId', book.id);

        let addedTags = [];
        if (currentBookTags.data?.length) {
            const currentTags = currentBookTags.data.map(a => a.tagId);
            const { data, error } = await this.supabaseService.client.from('books_tags')
                .delete()
                .eq('bookId', book.id)
                .not('tagId', 'in', `(${tags.map(t => t.id).join()})`);

            addedTags = tags.filter(a => !currentTags.includes(a));
        } else {
            addedTags = tags;
        }

        await this.supabaseService.client.from('books_tags')
            .upsert(addedTags.map(t => ({ bookId: book.id, tagId: t.id })));
    }

    public async getPublishers(): Promise<any[]> {
        const result = await this.supabaseService.client.from('publishers').select().order('name');
        return result.data ?? [];
    }

    public async getLanguages(): Promise<any[]> {
        const result = await this.supabaseService.client.from('Language')
            .select();
        return result.data ?? [];
    }

    public getBookCoverUrl(fileName?: string): string {
        return environment.coverStorage + (fileName + '.jpg');
    }

    public async getAuthors(): Promise<any[]> {
        const result = await this.supabaseService.client.from('authors')
            .select()
            .order('name', { ascending: true });
        return result.data ?? [];
    }

    public async addBookToReservation(bookId: number): Promise<boolean> {
        // First get active reservation for user, or create one if it doesn't exist yet
        let response: { data: any | null, error: any } = await this.supabaseService.client
            .from('baskets')
            .select('*')
            .eq('status', 0)
            .maybeSingle();
                
        if (response.error) {
            console.error(response.error);
            throw new Error('An error occured while fetching basket.');
        }

        let basket = response.data;
        if (!response.data) {
            response = await this.supabaseService.client
                .from('baskets')
                .insert([{}])
                .select('*')
                .single();

            if (response.error) {
                console.error(response.error);
                throw new Error('An error occured while fetching basket.');
            }

            basket = response.data?.basket;
        }

        let copyResponse: PostgrestSingleResponse<any[]> = await this.supabaseService.client
            .from('copies')
            .select('*')
            .eq('bookId', bookId)
            .limit(1);

        if (!copyResponse.data || copyResponse.data.length === 0 || copyResponse.error) {
            console.error(response.error);
            throw new Error('An error occured while fetching copy.'); 
        }

        const copies = copyResponse.data;
        await this.supabaseService.client
            .from('copies')
            .update({ 'basket_id': basket.id })
            .eq('id', copies[0].id);

        return true;
    }

    public async adminBookUpdate(clientName: string, clientEmail: string, copyId: number, state: number, comments: string, wantsToRegister: boolean): Promise<any> {
        if (state === State.reserved || state === State.borrowed) {
            await this.supabaseService.client
                .from('requests')
                .insert([{ 
                    guestName: clientName,
                    availability: 'N/A',
                    comments: `${clientEmail} ${wantsToRegister ? '(wilt registreren)' : ''}. Comments: ${comments}`,
                    borrowedDate: state === State.borrowed ? new Date() : null,
                    copyId
                }]);
        }

        await this.setBookState(copyId, state);
    }

    public async setBookState(copyId: number, state: State): Promise<void> {
        await this.supabaseService.client
             .from('copies')
             .update({ state })
             .eq('id', copyId);
    }

    public async closeReservation(reservationId: number): Promise<void> {
        await this.supabaseService.client
             .from('requests')
             .update({ closed: true })
             .eq('id', reservationId);
    }
    
    public async openReservation(reservationId: number): Promise<void> {
        await this.supabaseService.client
             .from('requests')
             .update({ closed: false })
             .eq('id', reservationId);
    }

    public async setBorrowDate(reservationId: number): Promise<void> {
        await this.supabaseService.client
            .from('requests')
            .update({ borrowedDate: new Date() })
            .eq('id', reservationId);
    }

    public async getRequests(): Promise<Request[]> {
        const select = `
            *,
            copies(*, books(*, authors(*))),
            users(*)
        `;

        const result = await this.supabaseService.client.from('requests')
            .select(select)
            .eq('closed', false);

        
        return result.data?.filter(data => data.copies.state === State.reserved) ?? [];
    }

    public async getBorrowedBooks(): Promise<Request[]> {
        const select = `
            *,
            copies(*, books(*, authors(*))),
            users(*)
        `;

        const result = await this.supabaseService.client.from('requests')
            .select(select)
            .eq('closed', false);

            return result.data?.filter(data => data.copies.state === State.borrowed) ?? [];
        }
}