import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
//DEPRECATED
@Component({
    selector: 'ui-svg',
    templateUrl: './svg.component.html',
    styleUrls: [
        './svg.component.scss'
    ]
})
export class SvgComponent {
    @Input() name: string = '';
    @Input() spin: boolean = false;
    @Input() color?: string;
    @Input() width?: number;
    @Input() height?: number;

    public styles: any = {
        height: '24px'
    };
}
