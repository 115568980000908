<nav class="navbar navbar-expand-xl navbar-dark bg-dark-900 theme-{{theme$|async}}">
  <a class="navbar-brand d-flex flex-row align-items-center" routerLink="/">
    <img class="logo shadow rounded-circle" src="assets/logo.svg">
    <span class="d-none d-sm-block brand-text shadow">Project Madrigal</span>
  </a>

  <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse navbar-items" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <ul class="navbar-nav mt-2 mt-xl-0">
      <li class="nav-item" [class.active]="isActive(link.route)" *ngFor="let link of headerLinks"
        (click)="onNavItemClick(link.route)">
        <a class="nav-link">
          {{ link.label }}
        </a>
      </li>
      <li class="nav-item d-lg-none" [class.active]="isActive(link.route)" *ngFor="let link of mobileLinks"
        (click)="onNavItemClick(link.route)">
        <a class="nav-link">
          {{ link.label }}
        </a>
      </li>
      <li class="nav-item" *ngFor="let link of adminLinks" (click)="onNavItemClick(link.route)">
        <a class="nav-link">
          {{ link.label }}
        </a>
      </li>
    </ul>
    <div class="navbar-nav">
      <div class="nav-item" *ngIf="(user$ | async) === undefined">
        <a class="nav-link text-primary-light" [routerLink]="['login']">
          <button class="btn btn-primary">Aanmelden</button>
        </a>
      </div>
      <li *ngIf="(user$ | async) as user" class="nav-item d-none d-lg-block" ngbDropdown>
        <a class="nav-link text-light-200" tabindex="0" ngbDropdownToggle id="navbarDropdown2" role="button">Hoi, {{
          user.username }} </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-end bg-dark">
          <a ngbDropdownItem class="profile-dropdown-item"
            (click)="$event.preventDefault();onNavItemClick('reservaties')">Reservaties</a>
          <a ngbDropdownItem class="profile-dropdown-item" (click)="$event.preventDefault();logout()">Uitloggen</a>
        </div>
      </li>
      <!-- <li class="nav-item" *ngIf="(user$ | async) as user" (click)="onNavItemClick(['reservaties'])">
        <a class="nav-link">
          Mijn reservaties
        </a>
      </li> -->
    </div>
    <div class="theme-toggle cursor-pointer">
      <img class="d-block" [class.d-none]="(theme$ | async) === 'dark'" src="assets/moon.svg" (click)="setTheme('dark')">
      <img class="d-block" [class.d-none]="(theme$ | async) === 'light'" src="assets/sun.svg" (click)="setTheme('light')">
    </div>
  </div>
</nav>