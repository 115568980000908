<form [formGroup]="bookForm" (ngSubmit)="onSubmit()" class="container mt-5 w-lg-75 w-xl-50">
    <!-- Title -->
    <div class="form-group pt-2">
        <label for="title">Titel:</label>
        <input type="text" formControlName="title" id="title" class="form-control"  />
    </div>

    <!-- ISBN10 -->
    <div class="form-group pt-2">
        <label for="isbn10">ISBN10:</label>
        <input type="text" formControlName="isbn10" id="isbn10" class="form-control" maxlength="10" />
    </div>

    <!-- ISBN13 -->
    <div class="form-group pt-2">
        <label for="isbn13">ISBN13:</label>
        <input type="text" formControlName="isbn13" id="isbn13" class="form-control" maxlength="13"  />
    </div>

    <!-- Author -->
    <div class="form-group pt-2">
        <label for="author">Auteur(s):</label>
        <ng-select formControlName="authors" [items]="authorOptions" bindLabel="name" [multiple]="true"
            class="form-control p-0" [addTag]="true"></ng-select>
    </div>

    <!-- Publisher -->
    <div class="form-group pt-2">
        <label for="publisher">Uitgever:</label>
        <ng-select class="mt-1 mb-2" name="publishers" [items]="publisherOptions" formControlName="publisher" placeholder="Uitgeverij" bindLabel="name" [addTag]="true">
      </ng-select>
    </div>

    <!-- Tags -->
    <div class="form-group pt-2">
        <label for="author">Genre(s):</label>
        <ng-select formControlName="tags" [items]="tagOptions" bindLabel="name" [multiple]="true"
            class="form-control p-0"></ng-select>
    </div>

    <!-- Description -->
    <div class="form-group pt-2">
        <label for="description">Beschrijving:</label>
        <textarea formControlName="description" id="description" class="form-control" rows="10"></textarea>
    </div>

    <!-- Page Count -->
    <div class="form-group pt-2">
        <label for="pageCount">Aantal Pagina's:</label>
        <input type="number" formControlName="pageCount" id="pageCount" class="form-control" min="0"  />
    </div>

    <!-- Published Year -->
    <div class="form-group pt-2">
        <label for="publishedYear">Jaar van Publicatie:</label>
        <input type="number" formControlName="publishedYear" id="publishedYear" class="form-control" min="0"
            max="{{ currentYear }}"  />
    </div>

    <!-- Book Cover -->
    <div class="form-group pt-2">
        <label for="bookCover">Boekomslag:</label>
        <!-- <input type="file" formControlName="bookCover" id="bookCover" class="form-control-file" /> -->
        <form>
            <label for="fileupload" class="rounded p-2 bg-yellow-color brand-font text-dark cursor-pointer">
                <input type="file" id="fileupload" accept="image/*" (change)="uploadImage($event)">
                Upload afbeelding
            </label>
        </form>
        <div *ngIf="image" class="mt-3 container d-flex flex-row justify-content-center">
            <img class="mx-3 img-lg" [src]="image">
        </div>

    </div>

    <p *ngFor="let alert of alerts" class=" w-100 w-md-75 my-3">
        <ngb-alert [type]="alert.type" (closed)="close(alert)">{{ alert.message }}</ngb-alert>
    </p>
    <button type="submit" class="mt-3 btn btn-primary">Opslaan</button>
</form>