import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../services/session.service';

@Injectable({
    providedIn: 'root'
})
export class IsAuthorizedGuard  {

    constructor(
        private sessionService: UserService,
        private router: Router
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        await this.sessionService.getUser();
        const user = await firstValueFrom(this.sessionService.user$);
        
        if (user?.isAdmin) {
            return true;
        }
        
        this.router.navigate(['./']);
        return false;
    }
}
