import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { LibraryService } from 'src/app/services/library.service';
import { Book, Tag, Publisher, UpdatePublisher, UpdateBook, InsertPublisher, Author } from 'src/app/types/supabase/supabase.models';

@Component({
    selector: 'app-book-detail-editor',
    templateUrl: './book-detail-edit.component.html'
})
export class BookDetailEditorComponent implements OnInit {
    // UI
    public showEdit: boolean = false;
    public errorMessage: string | null = null;

    public book!: Book;
    
    public breadcrumbs?: any[];
    
    public image: string = '';

    public tagOptions!: Tag[];
    public publisherOptions!: (Publisher | InsertPublisher)[];
    public bookForm!: FormGroup;
    public authorOptions!: Author[];
    public newPublisher: string = '';

    constructor(
        private libraryService: LibraryService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    public ngOnInit(): void {
        this.book = this.route.snapshot.data['book'];
        this.tagOptions = this.route.snapshot.data['tags'];
        this.authorOptions = this.route.snapshot.data['authors'];
        this.breadcrumbs = [
            { label: 'Boeken', routerLink: ['../../../'] },
            { label: this.book.title, routerLink: ['../'] },
            { label: 'Bewerken' }
        ];
        this.initForm();

        if (this.book.uuid) {
            this.image = this.libraryService.getBookCoverUrl(this.book.uuid);
        }

        this.libraryService.getPublishers().then(response => {
            this.publisherOptions = response;
        });
    }

    public initForm(): void {
        const publisher = this.book.publishers?.length
            ? this.book.publishers[0]
            : undefined;
        this.bookForm = this.formBuilder.group({
            bookTitle: [this.book.title, Validators.required],
            authors: [this.book.authors],
            publisher: [publisher, Validators.required],
            description: [this.book.description, Validators.required],
            isbn10: [this.book.isbn10],
            isbn13: [this.book.isbn13],
            tags: [this.book.tags],
            publishedYear: [this.book.publishedYear, [Validators.min(new Date().getFullYear()), Validators.max(new Date().getFullYear() + 5)]],
            pageCount: [this.book.pageCount, Validators.required],
            image: ['']
        });
    }


    public async submitBook(): Promise<void> {
        const book: UpdateBook = {
            id: this.book.id,
            title: this.bookForm.controls['bookTitle'].value,
            publishedYear: this.bookForm.controls['publishedYear'].value,
            pageCount: this.bookForm.controls['pageCount'].value,
            description: this.bookForm.controls['description'].value,
            isbn10: this.bookForm.controls['isbn10'].value,
            isbn13: this.bookForm.controls['isbn13'].value,
            uuid: this.book.uuid
        };
        const authors = this.bookForm.get('authors')?.value;
        const tags = this.bookForm.get('tags')?.value;
        const publishers = this.bookForm.get('publisher')?.value;

        await this.libraryService.updateBook(book, authors, tags, publishers);
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    addNewPublisher() {
        const newPublisher = this.newPublisher.trim();
        if (newPublisher && !this.publisherOptions.filter(p => p.name === newPublisher)) {
            this.publisherOptions.push({ name: newPublisher });
            this.bookForm.get('publisher')?.setValue(newPublisher);
        }
        this.newPublisher = '';
    }

    onFileSelected(event: any) {
        const file = event.target.files[0];
        this.bookForm.get('image')?.setValue(file);
    }

    public async addCopy(): Promise<void> {
        await this.libraryService.addCopy(this.book);
        this.book = await this.libraryService.getBook(this.book.id);
    }

    public uploadImage(event: any): void {
        if (event.target?.files?.length) {
            const file: File = event.target.files[0];
            this.libraryService.addImage(file).then((path: string | undefined) => {
                this.image = `https://uuxamqpddbrazazfzdqa.supabase.co/storage/v1/object/public/covers/${path}.jpg`;
                this.bookForm.controls['image'].setValue(path);
            });
        }
    }

    onSubmit() {
        if (this.bookForm.valid) {
            // Handle form submission here
            const formData = this.bookForm.value;
            console.log(formData);
        } else {
            // Handle form validation errors
        }
    }

    public setErrorMessage(message: string): void {
        this.errorMessage = message;
    }

}
