<div class="container p-3 w-md-75 w-xl-50">
    <app-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1>Wijzig Boek</h1>

    <p>
        <span>Boek: {{ book.title }}</span>
    </p>


    <form [formGroup]="reservationForm" (ngSubmit)="requestBook()" class="">

        <p>Kopie ID:</p>
        <ng-select class="mt-1 mb-2" name="status" [items]="book.copies" formControlName="selectedBook" bindLabel="id"
            placeholder="Status"></ng-select>
            <small *ngIf="!reservationForm.controls['selectedBook'].value">
                Aangezien een boek meerdere keren in de bibliotheek kan voorkomen,
                wordt voor elk kopie een ID voorzien. Laat wanneer nodig weten wanneer er een systeem moet
                komen om kopieen van elkaar te onderscheiden.
                <br><br>
                Voorlopig kan je dus een willekeurig nummer uitkiezen en het eerste beste kopie
                meegeven dat wordt gevonden.
                <br><br>
                Ter info: Het kopie-ID komt niet overeen met het boek-id.
            </small>
        <ng-container *ngIf="reservationForm.controls['selectedBook'].value as selectedBook">
            <p *ngIf="selectedBook.comment">Notities: {{ selectedBook.comment }}</p>
            <label for="state">Status:</label>
            <ng-select class="mt-1 mb-2" name="state" [items]="statusOptions" formControlName="state" bindLabel="label"
                bindValue="id" placeholder="Status"></ng-select>
            <small *ngIf="">
                Aangezien een boek meerdere keren in de bibliotheek kan voorkomen,
                wordt voor elk kopie een ID voorzien. Laat wanneer nodig weten wanneer er een systeem moet
                komen om kopieen van elkaar te onderscheiden.
                <br><br>
                Voorlopig kan je dus een willekeurig nummer uitkiezen en het eerste beste kopie
                meegeven dat wordt gevonden.
                <br><br>
                Ter info: Het kopie-ID komt niet overeen met het boek-id.
            </small>
            <ng-container *ngIf="reservationForm.controls['state'].value === 2 || reservationForm.controls['state'].value === 3">
                <div class="form-group">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="existing-user"
                            formControlName="existingUser">
                        <label class="form-check-label" for="existing-user">
                            <span *ngIf="reservationForm.controls['existingUser'].value === true">Bestaande
                                gebruiker</span>
                            <span *ngIf="reservationForm.controls['existingUser'].value === false">Geen gebruiker</span>
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-check form-switch"
                        *ngIf="reservationForm.controls['existingUser'].value === false">
                        <input class="form-check-input" type="checkbox" id="existing-user"
                            formControlName="wantsToRegister">
                        <label class="form-check-label" for="wantsToRegister">
                            <span *ngIf="reservationForm.controls['wantsToRegister'].value === true">Wilt een
                                account</span>
                            <span *ngIf="reservationForm.controls['wantsToRegister'].value === false">Wilt geen
                                account</span>
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="reservationForm.controls['existingUser'].value === true">
                    <div class="form-group">
                        <label for="name" class="form-label mt-3">Gebruikersnaam</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>
                </ng-container>
                <ng-container *ngIf="reservationForm.controls['existingUser'].value === false">
                    <div class="form-group">
                        <label for="name" class="form-label mt-3">Naam</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>
                    <ng-container *ngIf="reservationForm.controls['existingUser'].value === false">
                        <div class="form-group">
                            <label for="email" class="form-label mt-3">Email</label>
                            <input type="text" class="form-control" formControlName="email">
                        </div>
                        <small *ngIf="reservationForm.controls['wantsToRegister'].value === true">
                            Voorlopig is er nog geen geautomatiseerd systeem om deze personen een uitnodiging te sturen.
                            Dus je gaat dit emailadres manueel moeten contacteren en uitnodigen om de site te bezoeken
                            en te registreren!
                        </small>
                    </ng-container>
                </ng-container>
            </ng-container>
            <div class="form-group" *ngIf="selectedBook && selectedBook.state">
                <label for="comments" class="form-label mt-3">Extra info</label>
                <input type="text" class="form-control" formControlName="comments">
            </div>
            <button type="submit" class="mt-3 btn btn-primary"
                *ngIf="selectedBook && selectedBook.state">Opslaan</button>

        </ng-container>
    </form>
</div>