<div class="d-flex flex-column theme-{{ theme$ | async }}">
    <!-- Hidden H1 for accessibility -->
    <h1 class="d-none">Project Madrigal</h1>

    <!-- News Section -->
    <section *ngIf="post" class="news">
        <!-- <div class="section-title"> -->
            <h2 class="d-none d-xl-block">Nieuws</h2>
        <!-- </div> -->
        <div class="container">
            <h3>{{ post.title }}</h3>
            <div class="post-details">
                <span>{{ post.created_at | date:'fullDate'}}</span>
                <span class="d-none d-xl-block">|</span>
                <div class="d-flex flex-row">
                    <span>Nance</span>&nbsp;
                    <span class="tag">any/all</span>
                </div>
            </div>
            <div class="post-content">
                <img *ngIf="post.image" src="https://uuxamqpddbrazazfzdqa.supabase.co/storage/v1/object/public/newspostimg/{{post.image}}">
                <span [innerHTML]="post.htmlcontent"></span>
            </div>
            <!-- Commented for now. Future feature :) -->
            <!-- <hr>
            <div class="w-100">
                Reageer | 0 comments
            </div> -->
        </div>
    </section>

    <!-- Recommendations Section -->
    <section class="recommendations">
        <h2>Aanraders</h2>
        <div class="container">
            <div #recommendationCards class="recommendation" *ngFor="let recommendation of recommendations">
                <div class="book-card" (click)="goToBook(recommendation.id)">
                    <img src="https://uuxamqpddbrazazfzdqa.supabase.co/storage/v1/object/public/covers/{{recommendation.uuid}}.jpg">
                    <div class="text">
                        <div class="title" [title]="recommendation.title">{{ recommendation.title }}</div>
                        <span>{{ recommendation.authors }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Events Section -->
    <section *ngIf="event" class="events">
        <h2 class="d-none d-lg-block">Events</h2>
        <!-- Mobile version -->
        <div class="event-card d-md-none">
                <span class="small">
                    {{ event.eventDate | date:'d' }} {{ event.eventDate | date:'MMM' | removePeriod | uppercase }} - {{ event.eventTime }}
                </span>
                <h3>{{ event.title }}</h3>
                <span class="small" (click)="goToLink(event.google_maps_link)">
                    <img class="map-pin" src="assets/map-pin.svg"> {{ event.location }}
                </span>
            <img class="event-image" *ngIf="event.image" src="https://uuxamqpddbrazazfzdqa.supabase.co/storage/v1/object/public/events/{{event.image}}">
            <span class="description" [innerHTML]="event.description"></span>

            <button *ngIf="!isPastEvent" class="btn btn-light" (click)="goToLink(event.link)">Registreer je hier <img src="assets/external-link.svg"></button>
            <button *ngIf="isPastEvent" class="btn btn-light" disabled>Deelname gesloten</button>
        </div>

        <!-- Desktop version -->
        <div class="d-none d-md-grid event-card-desktop">
            <img class="event-image" *ngIf="event.image" src="https://uuxamqpddbrazazfzdqa.supabase.co/storage/v1/object/public/events/{{event.image}}">
            <div class="desktop-event-details">
                <h3>{{ event.title }}</h3>
                <div class="details">
                    <div class="event-time-location">
                        <div class="event-location"><img class="map-pin" src="assets/map-pin-dark.svg"> {{ event.location }}</div>
                        <div class="event-datetime"><img class="map-pin" src="assets/clock.svg"> {{ event.eventDate | date:'d' }} {{ event.eventDate | date:'MMM' | removePeriod | uppercase }} - {{ event.eventTime }}</div>
                    </div>
                    <span class="description" [innerHTML]="event.description"></span>
                    <!-- <button class="btn btn-link" (click)="goToLink(event.google_maps_link)">Open Map <img src="assets/external-link-light.svg"></button> -->

                    <button *ngIf="!isPastEvent" class="btn btn-outline-dark" (click)="goToLink(event.link)">Registreer je hier <img src="assets/external-link.svg"></button>
                    <button *ngIf="isPastEvent" class="btn btn-dark disabled-button" disabled>Deelname gesloten</button>
                </div>
            </div>
        </div>
    </section>

    <!-- FAQ Section -->
    <section class="faq ">
        <h2>FAQ</h2>
        <div class="container pt-0">
            <div class="accordion" id="accordionExample">
                <div *ngFor="let faqItem of faq; let i = index" class="accordion-item">
                  <h3 class="accordion-header" [id]="'heading' + i">
                    <button
                      class="accordion-button strong"
                      type="button"
                      data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse' + i"
                      [attr.aria-controls]="'collapse' + i"
                    >
                      {{ faqItem.question }}
                    </button>
                  </h3>
                  <div
                    [id]="'collapse' + i"
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="'heading' + i"
                  >
                    <div class="accordion-body">
                      <span [innerHTML]="faqItem.answer_html"></span>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </section>

    <!-- Contact -->
    <section class="contact">
        <iframe class="w-100 h-100" src="https://www.openstreetmap.org/export/embed.html?bbox=4.412459135055543%2C51.20649017227458%2C4.417351484298707%2C51.20914856048351&amp;layer=mapnik&amp;marker=51.20781770516324%2C4.414905309677124"></iframe>
        <div class="p-3 p-lg-5">
            <h2 class="d-none">Contact</h2>
            <h3 class="">Kom eens langs</h3>
            <div class="small">
                <p>
                    <strong>Peperfabriek</strong> (1e verdiep)<br>
                    Brueghelstraat 31<br>
                    2018 Antwerpen
                </p>
                <p>
                    Elke vrijdag:<br><strong>18:00 - 20:00</strong><br><br>
                    Elke laatste zondag van de maand:<br><strong>14:00 - 16:00</strong>
                </p>
                <p>
                    <strong>Algemeen</strong>: vankasteren70@gmail.com<br>
                    <strong>Events</strong>: events.madrigal@gmail.com
                </p>
            </div>
            
        </div>
    </section>
</div>
