<div>
    <div class="theme-{{theme$|async}}">
        <div class="filters">
            <div class="categories">
                <form *ngIf="filterForm" [formGroup]="filterForm">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button strong" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#categories" aria-expanded="true" aria-controls="categories">
                                    Categorieën
                                </button>
                            </h3>
                            <div id="categories" class="accordion-collapse collapse show" [class.show]="true"
                                aria-labelledby="categories">
                                <div class="accordion-body">
                                    <div formArrayName="categories">
                                        <div *ngFor="let tag of allCategories; let i = index" class="">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="tagcheckbox{{i}}"
                                                    [formControlName]="i" (change)="triggerSearch()">
                                                <label class="form-check-label cursor-pointer" for="tagcheckbox{{i}}">
                                                    {{ tag.displayName }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button strong" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#themes" aria-controls="themes">
                                    Thema's
                                </button>
                            </h3>
                            <div id="themes" class="accordion-collapse collapse show" aria-labelledby="themes">
                                <div class="accordion-body">
                                    <div formArrayName="themes">
                                        <div *ngFor="let tag of allThemes; let i = index" class="">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="themecheckbox{{i}}"
                                                    [formControlName]="i" (change)="triggerSearch()">
                                                <label class="form-check-label cursor-pointer" for="themecheckbox{{i}}">
                                                    {{ tag.displayName }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Accessibility title -->
<!-- <h2 class="">Filters</h2>
<div class="filter-section text-light">
    <div *ngIf="filter?.tagIds?.length">
        <span>Jouw filters: </span>
        <span class="active-filters">{{ getTagNamesById(filter.tagIds) }} </span>
    </div>
    <button class="btn btn-primary w-100" aria-controls="filterCollapse" aria-expanded="false"
        aria-label="Toggle filter" (click)="filterCollapsed = false">
        <img src="assets/sliders.svg"> Filter boeken
    </button>
</div> -->