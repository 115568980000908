import { Component, OnInit } from '@angular/core';
import { LibraryService } from 'src/app/services/library.service';
import { State } from 'src/app/types';
import { Request } from 'src/app/types/supabase/supabase.models';

enum LoadingState {
    NotLoading = 1,
    Loading = 2,
    Loaded = 3
}

type RequestWithLoading = Request & { loadingState: LoadingState };
type RequestWithDeadline = Request & { deadline?: Date };
type AdminRequest = RequestWithLoading & RequestWithDeadline;

@Component({
    selector: 'app-management',
    templateUrl: 'management.component.html',
    styleUrls: [
        'management.component.scss'
    ]
})

export class ManagementComponent implements OnInit {
    public requests: AdminRequest[] = [];
    public borrowed: AdminRequest[] = [];

    public statusOptions: { id: number, label: string }[] = [
        { id: 1, label: 'Beschikbaar' },
        { id: 2, label: 'Gereserveerd' },
        { id: 3, label: 'Ontleend' }
    ];

    constructor(
        private libraryService: LibraryService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.requests = (await this.libraryService.getRequests())
            .map(r => r as AdminRequest)
            .map(r => {
                r.loadingState = LoadingState.NotLoading;
                return r;
            });

        this.borrowed = (await this.libraryService.getBorrowedBooks())
            .map(r => r as AdminRequest)
            .map(r => {
                r.loadingState = LoadingState.NotLoading;
                const borrowedAsDate = r.borrowedDate ? new Date(r.borrowedDate) : new Date();
                r.deadline = new Date();
                r.deadline.setDate(borrowedAsDate.getDate() + 28);
                return r;
            });
    }

    public getImageSrc(uuid: string | null): string {
        if (uuid) {
            return this.libraryService.getBookCoverUrl(uuid);
        }
        return '';
    }

    public async updateStatus(request: RequestWithLoading, state: State): Promise<void> {
        request.loadingState = LoadingState.Loading;

        await this.libraryService.setBookState(request.copies.id, state);

        // Setting the state to something else than reserved should close the reservation
        if (state === State.available) {
            // closed = the reservation is not relevant anymore
            await this.libraryService.closeReservation(request.id);
        } else {
            // Ability to set it back in case of misclick
            await this.libraryService.openReservation(request.id);
        }

        if (state === State.borrowed) {
            await this.libraryService.setBorrowDate(request.id);
        }

        request.loadingState = LoadingState.Loaded;
    }
}