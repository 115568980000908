<div class="container">

    <div class="p-3 d-flex flex-column">
        <div class="d-flex flex-row justify-content-between">
            <h1>Actieve Reserveringen</h1>
            <button class="btn btn-primary d-none">Nieuwe reservering</button>
        </div>
        <div class="d-flex flex-column pb-2" *ngFor="let request of requests">
            <div class="d-flex flex-row w-100">
                <div class="pe-2 w-25"><strong>Details</strong></div>
                <div class="pe-2 w-25"><strong>Aanvraag door</strong></div>
                <div class="pe-2 w-25"><strong>Aanvraag info</strong></div>
                <div class="pe-2 w-25"><strong>Status</strong></div>
            </div>
            <div class="d-flex flex-row w-100">
                <div class="pe-2 w-25">
                    <div class="d-flex flex-row">
                        <div class="pe-2">
                            <a [routerLink]="['/boeken', 'id', request.copies.books.id]">
                                <img [src]="getImageSrc(request.copies.books.uuid)">
                            </a>
                        </div>
                        <div>
                            <small>
                                Boek #{{ request.copies.books.id }}<br>
                                Examplaar #{{ request.copies.id }}<br><br>
                                {{ request.copies.books.title }}<br>
                                <span *ngFor="let author of request.copies.books.authors">{{ author.name }}<br></span>
                            </small>    
                        </div>
                    </div>
                </div>
                <div class="pe-2 w-25">
                    <div *ngIf="request.userId">
                        {{ request.users.name }}<br>
                        {{ request.users.email }}<br>
                        {{ request.users.phone }}<br>
                    </div>
                    <div *ngIf="request.guestName">
                        {{ request.guestName }} (gast)
                    </div>
                </div>
                <div class="pe-2 w-25">
                    <div>
                        <span *ngIf="request.availability !== 'N/A'">{{ request.availability }}<br><br></span>
                        {{ request.comments }}<br>
                    </div>
                </div>
                <div class="w-25 d-flex flex-row">
                    <div class="w-75 pe-1">
                        <ng-select 
                            class="mt-1 mb-2" name="state" [items]="statusOptions" [(ngModel)]="request.copies.state" 
                            bindLabel="label" (ngModelChange)="updateStatus(request, $event)"
                            bindValue="id" placeholder="Status"></ng-select>
                        <span *ngIf="request.loadingState === 2">Aan het opslaan...</span>
                        <span *ngIf="request.loadingState === 3">Statuswijziging is opgeslagen!</span>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="pt-5">
            <h1>Uitgeleende boeken</h1>
            <div class="d-flex flex-column pb-2" *ngFor="let book of borrowed">
                <div class="d-flex flex-row w-100">
                    <div class="pe-2 w-25"><strong>Details</strong></div>
                    <div class="pe-2 w-25"><strong>Uitgeleend aan</strong></div>
                    <div class="pe-2 w-25"><strong>Aanvraag info</strong></div>
                    <div class="pe-2 w-25"><strong>Status</strong></div>
                </div>
                <div class="d-flex flex-row w-100 pb-5">
                    <div class="pe-2 w-25">
                        <div class="d-flex flex-row">
                            <div class="pe-2">
                                <a [routerLink]="['/boeken', 'id', book.copies.books.id]">
                                    <img [src]="getImageSrc(book.copies.books.uuid)">
                                </a>
                            </div>
                            <div>
                                <small>
                                    Boek #{{ book.copies.books.id }}<br>
                                    Examplaar #{{ book.copies.id }}<br><br>
                                    {{ book.copies.books.title }}<br>
                                    <span *ngFor="let author of book.copies.books.authors">{{ author.name }}<br></span>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="pe-2 w-25">
                        <div *ngIf="book.userId">
                            {{ book.users.name }}<br>
                            {{ book.users.email }}<br>
                            {{ book.users.phone }}<br>
                        </div>
                        <div *ngIf="book.guestName">
                            {{ book.guestName }} (gast)
                        </div>
                        <strong>Datum</strong><br>
                        {{ book.borrowedDate | date:'dd/MM/yyyy'}}<br>
                        <br>
                        <strong>Deadline</strong><br>
                        {{ book.deadline | date:'dd/MM/yyyy'}}<br>
                    </div>
                    <div class="pe-2 w-25">
                        <div>
                            <span *ngIf="book.availability !== 'N/A'">{{ book.availability }}<br><br></span>
                            {{ book.comments }}<br>
                        </div>
                    </div>
                    <div class="w-25 d-flex flex-row">
                        <div class="w-75 pe-1">
                            <ng-select 
                                class="mt-1 mb-2" name="state" [items]="statusOptions" [(ngModel)]="book.copies.state" 
                                bindLabel="label" (ngModelChange)="updateStatus(book, $event)"
                                bindValue="id" placeholder="Status"></ng-select>
                            <span *ngIf="book.loadingState === 2">Aan het opslaan...</span>
                            <span *ngIf="book.loadingState === 3">Statuswijziging is opgeslagen!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
