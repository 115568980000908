<div class="theme-{{theme$|async}}">
    <div class="container container-wide flex-column align-items-center">
        <div class="d-flex flex-row justify-content-end">
        </div>
        
        <div class="w-100 books-view">
            <!-- Desktop only Filter sidebar -->
            <div *ngIf="isDesktop" class="sidebar-wrapper">
                <div class="sidebar">
                    <app-books-desktop-filter></app-books-desktop-filter>
                </div>
            </div>
    
            <div id="content" class="h-100 ms-0 ms-lg-5">
                <div class="d-lg-none mb-1 mb-md-5 d-flex flex-row justify-content-between">
                    <h1 class="">Boeken</h1>
                    <button *ngIf="showAddBooks" class="m-2 btn btn-primary d-none d-md-block" context="primary" routerLink="toevoegen">+ Nieuw boek</button>    
                </div>
    
                <div *ngIf="!isDesktop" class="d-flex flex-column mb-5">
                    <app-books-mobile-filter></app-books-mobile-filter>
                </div>
    
                <div *ngIf="isDesktop">
                    <ng-container *ngIf="bookCount$ | async as bookCount">
                        <span class="intro-text" *ngIf="!filter.searchValue && !filter.tagIds && bookCount > 0">
                            Blader door onze collectie van maar liefst {{ bookCount }} boeken!
                        </span>
                    </ng-container>
                    
                    <span *ngIf="!!filter.searchValue">{{ bookCount$ }} zoekresultaten voor "<span class="filters">{{ filter.searchValue }}</span>".</span><br>
                    <span *ngIf="!!tagFilters.length && !!filter.searchValue">Jouw filters: <span class="filters">{{ tagFilters }}</span>.</span>
                    <span *ngIf="!!tagFilters.length && !filter.searchValue">{{ bookCount$ | async }} resultaten voor <span class="filters">{{ tagFilters }}</span>.</span>
                </div>
                <app-books-list></app-books-list>
            </div>
        </div>
        
    </div>
</div>
