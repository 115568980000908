import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, delay, filter, firstValueFrom, lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/session.service';
import { SearchService } from 'src/app/services/search.service';
import { UserInfo } from 'src/app/types/user/session-info.type';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    public searchValue: string | undefined = '';
    public isCollapsed = true;
    public theme$ = this.themeService.theme$;

    public user$: Observable<UserInfo | null | undefined> = this.sessionService.user$;
    
    private routerSubscription!: Subscription;
    
    @Input() public headerLinks: { label: string, route: string|string[] }[] = [];
    @Input() public mobileLinks: { label: string, route: string|string[] }[] = [];
    @Input() public adminLinks: { label: string, route: string|string[] }[] = [];

    // @Input() public theme: 'light'|'dark' = 'light';
    // @Output() public themeChange: EventEmitter<'light' | 'dark'> = new EventEmitter();

    // Set path manually for smoother navbar UI
    private activePath!: string;

    constructor(
        private searchService: SearchService,
        private sessionService: UserService,
        private themeService: ThemeService,
        private router: Router
    ) {
        this.activePath = router.url;
    }

    public async ngOnInit(): Promise<any> {

        const activeFilters = await firstValueFrom(this.searchService.activeFilters$);
        this.searchValue = activeFilters.searchValue;

        this.routerSubscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                // For smoother navbar UI
                delay(300)
            )
            // any on purpose because of error I don't want to bother with right now
            .subscribe((event: any) => {
                this.activePath = event.url;
            });
    }

    public isActive(route: string | string[]): boolean {
        const currentUrl = this.activePath.split('?')[0];
        const targetUrl = Array.isArray(route) ? route.join('/') : route;
    
        return currentUrl === targetUrl;
    }

    public onNavItemClick(route: string | string[]): void {
        this.isCollapsed = true;
        this.router.navigate(Array.isArray(route) ? route : [route]);
    }

    public onSearch(): void {
        this.searchService.searchText(this.searchValue, true);
    }

    public setTheme(theme: 'light'|'dark'): void {
        this.themeService.setTheme(theme);
    }

    public logout(): void {
        this.sessionService.logout().then(() => location.reload());
    }

    public ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }
}
