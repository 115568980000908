import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientLayoutComponent } from './layouts/client.layout';
import { AppComponent } from './app.component';
import { MainComponent } from './main.component';
import { ButtonComponent } from './shared/ui/button/button.component';
import { TagComponent } from './shared/ui/tag/tag.component';
import { SvgComponent } from './shared/ui/svg/svg.component';
import { HeaderComponent } from './shared/header/header.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { BooksListComponent } from './pages/client/books/books-list/books-list.component';
import { HomeComponent } from './pages/client/home/home.component';
import { BooksComponent } from './pages/client/books/books.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { BookRequestComponent } from './pages/client/book-request/book-request.component';
import { BookDetailComponent } from './pages/client/book-detail/book-detail.component';
import { AboutComponent } from './pages/client/about/about.component';
import { PracticalInfoComponent } from './pages/client/practical-info/practical-info.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { BookDetailEditorComponent } from './pages/client/book-detail-edit/book-detail-edit.component';
import { BookRequestAdminComponent } from './pages/client/book-request-admin/book-request.admin.component';
import { AddBookComponent } from './pages/admin/add-book/add-book.component';
import { ManagementComponent } from './pages/admin/management/management.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { registerLocaleData } from '@angular/common';
import localeBE from '@angular/common/locales/nl-BE';
import { RemovePeriodPipe } from './shared/pipes/remove-period.pipe';
import { BooksMobileFilterComponent } from './pages/client/books/books-mobile-filter/books-mobile-filter.component';
import { BooksDesktopFilterComponent } from './pages/client/books/books-desktop-filter/books-desktop-filter.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ReservationsComponent } from './pages/client/reservations/reservations.component';
import { StickySidebarDirective } from './shared/directives/sticky-sidebar.directive';
import { ReservationsStep2Component } from './pages/client/reservations/step2/reservations-step2.component';

registerLocaleData(localeBE);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    BreadcrumbsComponent,
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    TagComponent, 
    SvgComponent,
    ClientLayoutComponent,
    BooksMobileFilterComponent,
    BooksDesktopFilterComponent,
    BooksListComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    BooksComponent,
    BookDetailComponent,
    BookRequestComponent,
    AboutComponent,
    PracticalInfoComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    BookRequestAdminComponent,
    AddBookComponent,
    BookDetailEditorComponent,
    ManagementComponent,
    RemovePeriodPipe,
    ReservationsComponent,
    StickySidebarDirective,
    ReservationsStep2Component
  ],
  imports: [
    BrowserModule,
    BarcodeScannerLivestreamModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbAlertModule,
    NgxTypedJsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-BE' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
