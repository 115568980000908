<div class="bg-light-200">
    <div class="container">
        <h1>Inloggen</h1>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <form [formGroup]="form" class="w-100 w-md-50 w-xl-33">
                <label for="email" class="form-label mt-3">E-mailadres</label>
                <div class="input-group">
                    <input type="email" class="form-control" formControlName="email">
                </div>
        
                <label for="password" class="form-label mt-3">Wachtwoord</label>
                <div class="input-group">
                    <input type="password" class="form-control" formControlName="password" (keydown.enter)="login()">
                </div>
            </form>

            <div class="container d-flex flex-column align-items-center justify-content-center my-3">
                <ui-button context="primary" (clicked)="login()" [disabled]="loading">Log in</ui-button>
            
                <p class="pt-3 d-flex flex-column align-items-center">
                    <span>
                        Heb je nog geen account? 
                        <a href="#" [routerLink]="['../', 'register']">Registreer</a> je dan hier!
                    </span>
                    <a [routerLink]="['../', 'forgot-password']">Wachtwoord vergeten?</a>
                </p>
            </div>
        
            <span *ngIf="notExistWarning" class="text-danger">
                Geen account gevonden met je opgegeven email en wachtwoord.
            </span>
        </div>
    </div>
</div>