import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LibraryService } from 'src/app/services/library.service';
import { TagsService } from 'src/app/services/tags.service';
import { Author, InsertBook, InsertPublisher, Publisher, Tag } from 'src/app/types/supabase/supabase.models';

interface Alert {
	type: string;
	message: string;
}

@Component({
    templateUrl: './add-book.component.html'
})
export class AddBookComponent implements OnInit {
    public bookForm!: FormGroup;
    public authorOptions: any[] = [];
    public publisherOptions!: (Publisher | InsertPublisher)[];
    public tagOptions: any[] = [];
    public image?: string;
    public currentYear: number = new Date().getFullYear();
    public alerts: Alert[] = [];

    constructor(
        private fb: FormBuilder,
        private libraryService: LibraryService,
        private tagsService: TagsService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    public ngOnInit(): void {
        this.libraryService.getAuthors().then(authors => {
            this.authorOptions = authors;
        });
        this.tagsService.getAllTags().then(tags => {
            this.tagOptions = tags;
        })
        this.initializeForm();
        this.libraryService.getPublishers().then(response => {
            this.publisherOptions = response;
        });
    }

    public initializeForm(): void {
        this.bookForm = this.fb.group({
            title: ['', Validators.required],
            isbn10: [undefined, [Validators.maxLength(10)]],
            isbn13: [undefined, [Validators.maxLength(13)]],
            authors: [[]],
            tags: [[]],
            description: [''],
            pageCount: [undefined, []],
            publishedYear: [undefined, []],
            bookCover: [undefined]
        });
    }

    public onSubmit(): void {
        // Handle form submission
        if (this.bookForm.valid) {
            const bookToAdd: InsertBook = {
                title: this.bookForm.controls['title'].value,
                isbn10: this.bookForm.controls['isbn10'].value,
                isbn13: this.bookForm.controls['isbn13'].value,
                description: this.bookForm.controls['description'].value,
                pageCount: this.bookForm.controls['pageCount'].value,
                publishedYear: this.bookForm.controls['publishedYear'].value,
                uuid: this.bookForm.controls['bookCover'].value,
                syncedFrom: []
            };
            const authors: Author[] = this.bookForm.controls['authors'].value;
            const tags: Tag[] = this.bookForm.controls['tags'].value;
            this.libraryService.addBook(bookToAdd, authors, tags)
                .then(result => {
                    this.alerts.push({ type: 'success', message: 'Toegevoegd! Je wordt zo dadelijk doorgestuurd naar de nieuwe pagina.' });
                    this.router.navigate(['../id', result], { relativeTo: this.route });
                });
        } else {
            this.alerts.push({ type: 'danger', message: 'Er is iets misgelopen... Maak een screenshot en contacteer de Project Madrigal Dev chat op WhatsApp...' });
        }
    }
    
    public close(alert: Alert): void {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    public uploadImage(event: any): void {
        if (event.target?.files?.length) {
            const file: File = event.target.files[0];
            this.libraryService.addImage(file).then((path: string | undefined) => {
                this.image = `https://uuxamqpddbrazazfzdqa.supabase.co/storage/v1/object/public/covers/${path}.jpg`;
                this.bookForm.controls['bookCover'].setValue(path);
            });
        }
    }
}