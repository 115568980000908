import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/session.service";
import { SupabaseService } from "src/app/services/supabase.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
    selector: 'app-reservations',
    templateUrl: './reservations.component.html',
    styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {
    public basket!: any;
    public isGift: boolean = false;
    theme$ = this.themeService.theme$;

    constructor(
        private supabaseService: SupabaseService,
        private userService: UserService,
        private themeService: ThemeService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ){}

    public async ngOnInit(): Promise<void> {
        this.basket = await this.getBasket();
    }

    public async getBasket(): Promise<any> {
        const basket = await this.userService.getBasket();
        return basket;
    }

    public async removeFromBasket(book: any): Promise<any> {
        console.log(book)
        const copy = this.basket.copies.find((copy: any) => copy.bookId == book.id);

        const { data, error } = await this.supabaseService.client
            .from('copies')
            .update({ 'basket_id': null })
            .eq('id', copy.id);

        this.basket = await this.getBasket();
    }

    public goToNextStep(): void {
        this.router.navigate(['../','2'], { relativeTo: this.activatedRoute })
    }
}