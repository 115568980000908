import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'ui-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
    @Input() color?: 'primary' | 'secondary';
    @Input() active: boolean = false;

    public theme$ = this.themeService.theme$;

    constructor(
        private themeService: ThemeService
    ) { }

    ngOnInit(): void { }
}
