import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/config.service';
import { LibraryService } from 'src/app/services/library.service';
import { SupabaseService } from 'src/app/services/supabase.service';
import { Book, Copy } from 'src/app/types/supabase/supabase.models';

interface Alert {
	type: string;
	message: string;
}

@Component({
    selector: 'app-book-request-admin',
    templateUrl: './book-request-admin.component.html'
})
export class BookRequestAdminComponent implements OnInit {
    public book!: Book;
    public imageUrl: string = '';
    public breadcrumbs!: any[]
    public showEdit: boolean = false;
    public bookRequestText: string = '';
    public alerts: Alert[] = [];

    // public statusForm!: FormArray;
    public reservationForm: FormGroup = new FormGroup({
        selectedBook: new FormControl(undefined),
        state: new FormControl(undefined),
        existingUser: new FormControl(false),
        wantsToRegister: new FormControl(false),
        name: new FormControl(''),
        email: new FormControl(''),
        comments: new FormControl('')
    });

    public statusOptions: { id: number, label: string }[] = [
        { id: 1, label: 'Beschikbaar' },
        { id: 2, label: 'Gereserveerd' },
        { id: 3, label: 'Ontleend' },
        { id: 4, label: 'Beschadigd' },
        { id: 5, label: 'Verloren' },
        { id: 6, label: 'Inkijkexemplaar' }
    ];
    
    public errorMessage: string | null = null;
    public showSubmit: boolean = true;

    private get bookTitle() {
      return this.book?.title;
    }
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private libraryService: LibraryService,
        private configurationService: ConfigurationService,
        private supabaseService: SupabaseService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.book = this.route.snapshot.data['book'];
        // this.statusForm.controls['status'].setValue(this.book.copies.)

        this.breadcrumbs = [
            { label: 'Boeken', routerLink: ['../../../'] },
            { label: this.bookTitle, routerLink: ['../'] },
            { label: 'Reserveren' }
        ];

        this.bookRequestText = await this.configurationService.getBookRequestText();
    }

    public requestBook(): void {
        this.libraryService.adminBookUpdate(
            this.reservationForm.controls['name']?.value,
            this.reservationForm.controls['email']?.value,
            this.book.copies[0].id,
            this.reservationForm.controls['state'].value,
            this.reservationForm.controls['comments'].value,
            this.reservationForm.controls['wantsToRegister']?.value
        ).then((response: any) => {
            this.showSubmit = false;
            this.router.navigate(['../'], { relativeTo: this.route })
        });
    }

    public close(alert: Alert): void {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
}
