
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../services/session.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public notExistWarning = false;
    public loading = false;

    public breadcrumbs = [
        { label: 'Login' }
    ];

    constructor(
        private sessionService: UserService,
        private router: Router
    ) { }

    public form = new FormGroup({
        email: new FormControl(''),
        password: new FormControl('')
    });

    async ngOnInit(): Promise<void> {
        await this.sessionService.getUser();
        const user = await firstValueFrom(this.sessionService.user$);
        if (user) {
            this.router.navigate(['./']);
        }
    }

    public disable(): void {
        this.form.disable();
        this.loading = true;
    }

    public async login(): Promise<void> {
        await this.sessionService.login({
            email: this.form.controls['email'].value,
            password: this.form.controls['password'].value
        });
        firstValueFrom(this.sessionService.user$)
            .then((session) => {
                if (session) {
                    location.reload();
                } else {
                    this.notExistWarning = true;
                    this.form.enable();
                    this.loading = false;
                }
            })
    }
}
