import { Component, OnInit } from '@angular/core';
import { filter, firstValueFrom } from 'rxjs';
import { UserService } from '../services/session.service';
import { SearchService } from '../services/search.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ThemeService } from '../services/theme.service';

@Component({
    templateUrl: './client.layout.html',
    styleUrls: ['./client.layout.scss']
})
export class ClientLayoutComponent implements OnInit {
    public routes = [
        { label: 'Home', route: '/' },
        { label: 'Boeken', route: ['/boeken'] },
        { label: 'Over ons', route: ['/over-project-madrigal'] }
    ];
    public mobileRoutes = [
        { label: 'Aanmelden', route: ['/login'] },
        { label: 'Mijn reservaties', route: ['/reservaties'] },
    ]
    public adminRoutes: { label: string, route: string[] }[] = [
        // Don't add them here! Add them in the ngOnInit function
    ];

    public searchValue?: string;
    public isHomePage?: boolean;
    // public theme: 'light'|'dark' = 'light';
    public theme$ = this.themeService.theme$;

    public typed: string[] = [
        'queer',
        'gay',
        'lesbisch',
        'bi',
        'non-binair',
        'transgender',
        'agender',
        'interseks',
        'ace',
        'twijfelend',
        'out of spoons',
        'welkom'
    ]
    
    constructor(
        private sessionService: UserService,
        private searchService: SearchService,
        private themeService: ThemeService,
        private router: Router
    ) {}
    
    public async ngOnInit(): Promise< void > {
        this.isHomePage = this.router.url === '/';

        const savedTheme = localStorage.getItem('theme');
        if (savedTheme){
            this.themeService.setTheme(savedTheme as ('light'|'dark') ?? 'light');
}
        this.router.events
            .pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd) // Type guard for NavigationEnd
            )
            .subscribe((event: NavigationEnd) => {
                this.isHomePage = event.urlAfterRedirects === '/';
            });
        
        const user = await firstValueFrom(this.sessionService.user$.pipe(filter(user => !!user)));
        if (user?.isAdmin) {
            this.adminRoutes = [
                { label: 'Beheer', route: ['/boeken/beheer'] }
            ];
        }
    }

    public onSearch(): void {
        this.searchService.searchText(this.searchValue, true);
    }
}
