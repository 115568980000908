import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private _theme$: BehaviorSubject<'dark'|'light'> = new BehaviorSubject<'dark'|'light'>('light');
    public theme$: Observable<'dark'|'light'> = this._theme$.asObservable();
    
    public setTheme(theme: 'dark'|'light') {
        if (theme === 'dark') {
            document.body.classList.remove('theme-light');
            document.body.classList.add('theme-dark');
        }
        if (theme === 'light') {
            document.body.classList.remove('theme-dark');
            document.body.classList.add('theme-light');
        }
        this._theme$.next(theme);
        localStorage.setItem('theme', theme);
    }
}