<div class="layout theme-{{ theme$ | async }}">
    <app-header [headerLinks]="routes" [mobileLinks]="mobileRoutes" [adminLinks]="adminRoutes"></app-header>

    <section *ngIf="!isHomePage" class="search d-flex flex-column align-items-center p-2">
        <form>
            <!-- Hidden label for accessibility -->
            <label for="book-search" class="d-none">Vind een boek in de bib van Project Madrigal:</label>
            <input id="book-search" type="text" class="form-control book-search search-icon"
                placeholder="Zoek op titel, auteur, isbn..." aria-label="Zoeken" aria-describedby="button-search"
                [(ngModel)]="searchValue" ngDefaultControl [ngModelOptions]="{standalone: true}"
                (keydown.enter)="onSearch()">
        </form>
    </section>
    <section *ngIf="isHomePage" class="hero">
        <div class="hero-text">
            <!-- Hidden header for accessibility -->
            <h2 class="d-none">Zoek</h2>
            <span>Ik ben&nbsp;</span>
            <ngx-typed-js [strings]="typed" [typeSpeed]="50">
                <span class="typing"></span>
            </ngx-typed-js>
        </div>
        <form>
            <!-- Hidden label for accessibility -->
            <label for="book-search" class="d-none">Vind een boek in de bib van Project Madrigal:</label>
            <input id="book-search" type="text" class="form-control book-search search-icon"
                placeholder="Zoek op titel, auteur, isbn..." aria-label="Zoeken" aria-describedby="button-search"
                [(ngModel)]="searchValue" ngDefaultControl [ngModelOptions]="{standalone: true}"
                (keydown.enter)="onSearch()">
        </form>
    </section>

    <router-outlet></router-outlet>

    <app-footer></app-footer>
</div>