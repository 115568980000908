import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removePeriod'
})
export class RemovePeriodPipe implements PipeTransform {
  transform(value: string | null): string | null {
    return value ? value.replace('.', '') : null; 
  }
}
