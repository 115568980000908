import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { LibraryService } from '../../../services/library.service';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { Author, Book } from 'src/app/types/supabase/supabase.models';
import { SupabaseService } from 'src/app/services/supabase.service';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';

interface NewsPost { title: string, htmlcontent: string, created_at: string, image: string }
interface Recommendation { title: string, authors: string, uuid: string, id: number }

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: [
        'styles/news.home.component.scss',
        'styles/recommendations.home.component.scss',
        'styles/events.home.component.scss',
        'styles/faq.home.component.scss',
        'styles/contact.home.component.scss'
    ]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('recommendationCards') recommendationCards!: QueryList<ElementRef>;
    private observer!: IntersectionObserver;

    public searchValue: string | undefined = '';

    public post: NewsPost | null = null;
    public recommendations: Recommendation[] = [];

    public event?: any;
    public isPastEvent: boolean = false;

    public faq: any[] = [];

    private recommendationCardsSubscription?: Subscription;

    public theme$ = this.themeService.theme$;

    constructor(
        private libraryService: LibraryService,
        private searchService: SearchService,
        private themeService: ThemeService,
        private router: Router,
        private supabaseService: SupabaseService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.post = await this.getLatestPost();
        this.recommendations = await this.getRecommendations();
        this.event = await this.getNextOrLatestEvent();
        this.faq = await this.getFaq();
    }

    public ngAfterViewInit(): void {
        if (localStorage.getItem('newbie:swipehint')) {
            return;
        }

        this.recommendationCardsSubscription = this.recommendationCards.changes.subscribe(() => {
            const firstCard = this.recommendationCards.first;

            this.observer = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    firstCard.nativeElement.classList.add('swipe-hint');
                    this.observer.disconnect();
                    localStorage.setItem('newbie:swipehint', 'true');
                }
            });
            this.observer.observe(firstCard.nativeElement);
          });
        
      }

    public async getLatestPost(): Promise<NewsPost | null> {
        let { data: posts, error } = await this.supabaseService.client
            .from('posts')
            .select('*')
            .limit(1)

        return posts ? posts[0] : null;
    }

    public async getRecommendations(): Promise<Recommendation[]> {
        let { data: rawBooks, error } = await this.supabaseService.client
            .from('books')
            .select('*, authors_books(authors(*))')
            .eq('recommended', true);

        if (error || !rawBooks) {
            console.error(error);
            return [];
        }

        const books: Recommendation[] = rawBooks.map((book: any) => ({
            ...book,
            authors: book.authors_books.map((ab: any) => ab.authors.name).join(', ')
        }));

        return books;
    }

    public async getNextOrLatestEvent(): Promise<any> {
        const now = new Date().toISOString(); // Current timestamp

        // Query for the nearest future event
        const { data: futureEvent, error: futureError } = await this.supabaseService.client
            .from('events')
            .select('*')
            .gt('eventDate', now) 
            .order('eventDate', { ascending: true }) 
            .limit(1);

        // Query for the most recent past event
        const { data: pastEvent, error: pastError } = await this.supabaseService.client
            .from('events')
            .select('*')
            .lt('eventDate', now) 
            .order('eventDate', { ascending: false }) 
            .limit(1); 

        if (futureError || pastError) {
            console.error('Error fetching events:', futureError || pastError);
            return null;
        }

        const selectedEvent = futureEvent?.[0] || pastEvent?.[0];
        this.isPastEvent = futureEvent.length === 0;

        return selectedEvent;
    }

    public async getFaq(): Promise<any[]> {
        let { data: faq, error } = await this.supabaseService.client
            .from('faq')
            .select('*')
        
        if (error || !faq) {
            console.error(error);
            return [];
        }

        return faq;
    }

    public authorNames(authors: Author[]): string[] {
        return authors.map(author => author.name);
    }

    public goToBook(id: number): void {
        this.router.navigate(['boeken', 'id', id]);
    }

    public getImageUrl(name: string): string {
        return this.libraryService.getBookCoverUrl(name);
    }

    public onSearch(): void {
        this.searchService.searchText(this.searchValue, true);
    }

    public getDate(date: string): Date {
        return new Date(date);
    }

    public goToLink(link: string): void {
        window.open(link, '_blank');
    }

    public ngOnDestroy(): void {
        this.recommendationCardsSubscription?.unsubscribe();
    }
}
