import { Component, OnInit } from "@angular/core";
import { SupabaseService } from "src/app/services/supabase.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
    templateUrl: './reservations-step2.component.html',
    styleUrls: ['./reservations-step2.component.scss']
})
export class ReservationsStep2Component implements OnInit {
    // public basket!: any;
    // public isGift: boolean = false;
    // theme$ = this.themeService.theme$;

    constructor(
        private supabaseService: SupabaseService,
        private themeService: ThemeService
    ){}

    public async ngOnInit(): Promise<void> {
        // this.basket = await this.getBasket();
    }
}